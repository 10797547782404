import {createRouter, createWebHistory} from 'vue-router'


const routes = [
    {
        path: '/',
        component: () => import('@/views/pages/MyIndex'),
        children: [
            {
                name: 'PaymentAccepted',
                path: '/paymentaccepted',
                alias: ['/affaldplus/order/paymentaccepted', '/fredericia/order/paymentaccepted', '/helsingoerforsyning/order/paymentaccepted', '/middelfartkommune/order/paymentaccepted', 
                    '/nordfynskommune/order/paymentaccepted', '/nordvaerk/order/paymentaccepted', '/norfos/order/paymentaccepted', '/arc/order/paymentaccepted', '/vestfor/order/paymentaccepted', '/ikast-brande/order/paymentaccepted',
                    '/optidata/order/paymentaccepted', '/demo/order/paymentaccepted', '/arwos/order/paymentaccepted', '/dev1/order/paymentdeclined', '/dev2/order/paymentaccepted', '/dev3/order/paymentaccepted', '/horsenskommune/order/paymentaccepted' ],
                component: () => import('@/views/pages/PaymentAccepted'),
                meta: {
                    title: 'Betaling modtaget',
                    locale: 'da'
                }
            },
            {
                name: 'SePaymentAccepted',
                path: '/sepaymentaccepted',
                alias: ['/sedemo/order/paymentaccepted', '/hassleholm/order/paymentaccepted/', '/sam/order/paymentaccepted' ],
                component: () => import('@/views/pages/PaymentAccepted'),
                meta: {
                    title: 'Betaling modtaget',
                    locale: 'se'
                }
            },
            {
                name: 'PaymentDeclined',
                path: '/paymentdeclined',
                alias: ['/affaldplus/order/paymentdeclined', '/fredericia/order/paymentdeclined', '/helsingoerforsyning/order/paymentdeclined', '/middelfartkommune/order/paymentdeclined',
                    '/nordfynskommune/order/paymentdeclined', '/nordvaerk/order/paymentdeclined', '/norfos/order/paymentdeclined', '/arc/order/paymentdeclined', '/vestfor/order/paymentdeclined', '/ikast-brande/order/paymentdeclined',
                    '/optidata/order/paymentdeclined', '/demo/order/paymentdeclined', '/arwos/order/paymentdeclined', '/dev1/order/paymentdeclined', '/dev2/order/paymentdeclined', '/dev3/order/paymentdeclined', '/horsenskommune/order/paymentdeclined'],
                component: () => import('@/views/pages/PaymentDeclined'),
                meta: {
                    title: 'Betaling fejlede',
                    locale: 'da'
                }

            },
            {
                name: 'SePaymentDeclined',
                path: '/sepaymentdeclined',
                alias: ['/sedemo/order/paymentdeclined', '/hassleholm/order/paymentaccepted/', '/sam/order/paymentdeclined'],
                component: () => import('@/views/pages/PaymentDeclined'),
                meta: {
                    title: 'Betaling fejlede',
                    locale: 'se'
                }

            },
            {
                name: 'CreateOrder',
                path: '/createorder',
                component: () => import('@/views/default/CreateOrder'),
                meta: {
                    title: 'Opret ordre'
                }
            },
            {
                name: 'affaldplus',
                path: '/affaldplus/order',
                component: () => import('@/views/default/DefaultOrder'),
                meta: {
                    title: 'AffaldPlus',
                    locale: 'da'
                }
            },
            {
                name: 'affaldpluscreateorder',
                path: '/affaldplus/createorder',
                component: () => import('@/views/default/CreateOrder'),
                meta: {
                    title: 'AffaldPlus',
                    locale: 'da'
                }
            },
            {
                name: 'arc',
                path: '/arc/order',
                component: () => import('@/views/default/DefaultOrder'),
                meta: {
                    title: 'ARC',
                    locale: 'da'
                }
            },
            {
                name: 'arccreateorder',
                path: '/arc/createorder',
                component: () => import('@/views/default/CreateOrder'),
                meta: {
                    title: 'ARC',
                    locale: 'da'
                }
            },
            {
                name: 'arwos',
                path: '/arwos/order',
                component: () => import('@/views/default/DefaultOrder'),
                meta: {
                    title: 'Arwos',
                    locale: 'da'
                }
            },
            {
                name: 'arwoscreateorder',
                path: '/arwos/createorder',
                component: () => import('@/views/default/CreateOrder'),
                meta: {
                    title: 'Arwos',
                    locale: 'da'
                }
            },
            {
                name: 'fredericia',
                path: '/fredericia/order',
                component: () => import('@/views/default/DefaultOrder'),
                meta: {
                    title: 'Fredericia',
                    locale: 'da'
                }
            },
            {
                name: 'fredericiacreateorder',
                path: '/fredericia/createorder',
                component: () => import('@/views/default/CreateOrder'),
                meta: {
                    title: 'Fredericia',
                    locale: 'da'
                }
            },
            {
                name: 'helsingoerforsyning',
                path: '/helsingoerforsyning/order',
                component: () => import('@/views/default/DefaultOrder'),
                meta: {
                    title: 'HelsingoerForsyning',
                    locale: 'da'
                }
            },
            {
                name: 'helsingoerforsyningcreateorder',
                path: '/helsingoerforsyning/createorder',
                component: () => import('@/views/default/CreateOrder'),
                meta: {
                    title: 'HelsingoerForsyning',
                    locale: 'da'
                }
            },
            {
                name: 'hassleholm',
                path: '/hassleholm/order',
                component: () => import('@/views/default/DefaultOrder'),
                meta: {
                    title: 'HM AB',
                    locale: 'se'
                }
            },
            {
                name: 'hassleholmcreateorder',
                path: '/hassleholm/createorder',
                component: () => import('@/views/default/CreateOrder'),
                meta: {
                    title: 'HM AB',
                    locale: 'se'
                }
            },
            {
                name: 'horsenskommune',
                path: '/horsenskommune/order',
                component: () => import('@/views/default/DefaultOrder'),
                meta: {
                    title: 'HorsensKommune',
                    locale: 'da'
                }
            },
            {
                name: 'horsenskommunecreateorder',
                path: '/horsenskommune/createorder',
                component: () => import('@/views/default/CreateOrder'),
                meta: {
                    title: 'HorsensKommune',
                    locale: 'da'
                }
            },
            {
                name: 'sam',
                path: '/sam/order',
                component: () => import('@/views/default/DefaultOrder'),
                meta: {
                    title: 'SÅM',
                    urlName: 'sam',
                    locale: 'se'
                }
            },
            {
                name: 'samcreateorder',
                path: '/sam/createorder',
                component: () => import('@/views/default/CreateOrder'),
                meta: {
                    title: 'SÅM',
                    urlName: 'sam',
                    locale: 'se'
                }
            },
            {
                name: 'ikast-brande',
                path: '/ikast-brande/order',
                component: () => import('@/views/default/DefaultOrder'),
                meta: {
                    title: 'Ikast-Brande',
                    locale: 'da'
                }
            },
            {
                name: 'ikast-brandecreateorder',
                path: '/ikast-brande/createorder',
                component: () => import('@/views/default/CreateOrder'),
                meta: {
                    title: 'Ikast-Brande',
                    locale: 'da'
                }
            },
            {
                name: 'middelfartkommune',
                path: '/middelfartkommune/order',
                component: () => import('@/views/default/DefaultOrder'),
                meta: {
                    title: 'Middelfartkommune',
                    locale: 'da'
                }
            },
            {
                name: 'middelfartkommunecreateorder',
                path: '/middelfartkommune/createorder',
                component: () => import('@/views/default/CreateOrder'),
                meta: {
                    title: 'Middelfartkommune',
                    locale: 'da'
                }
            },
            {
                name: 'nordfynskommune',
                path: '/nordfynskommune/order',
                component: () => import('@/views/default/DefaultOrder'),
                meta: {
                    title: 'NordfynsKommune',
                    locale: 'da'
                }
            },
            {
                name: 'nordfynskommunecreateorder',
                path: '/nordfynskommune/createorder',
                component: () => import('@/views/default/CreateOrder'),
                meta: {
                    title: 'NordfynsKommune',
                    locale: 'da'
                }
            },
            {
                name: 'nordvaerk',
                path: '/nordvaerk/order',
                component: () => import('@/views/default/DefaultOrder'),
                meta: {
                    title: 'Nordvaerk',
                    locale: 'da'
                }
            },
            {
                name: 'nordvaerkcreateorder',
                path: '/nordvaerk/createorder',
                component: () => import('@/views/default/CreateOrder'),
                meta: {
                    title: 'Nordvaerk',
                    locale: 'da'
                }
            },
            {
                name: 'norfos',
                path: '/norfos/order',
                component: () => import('@/views/default/DefaultOrder'),
                meta: {
                    title: 'Norfos',
                    locale: 'da'
                }
            },
            {
                name: 'norfoscreateorder',
                path: '/norfos/createorder',
                component: () => import('@/views/default/CreateOrder'),
                meta: {
                    title: 'Norfos',
                    locale: 'da'
                }
            },
            {
                name: 'odsherred',
                path: '/odsherred/order',
                component: () => import('@/views/default/DefaultOrder'),
                meta: {
                    title: 'Odsherred',
                    locale: 'da'
                }
            },
            {
                name: 'odsherredcreateorder',
                path: '/odsherred/createorder',
                component: () => import('@/views/default/CreateOrder'),
                meta: {
                    title: 'Odsherred',
                    locale: 'da'
                }
            },
            {
                name: 'refa',
                path: '/refa/order',
                component: () => import('@/views/default/DefaultOrder'),
                meta: {
                    title: 'Refa',
                    locale: 'da'
                }
            },
            {
                name: 'refacreateorder',
                path: '/refa/createorder',
                component: () => import('@/views/default/CreateOrder'),
                meta: {
                    title: 'Refa',
                    locale: 'da'
                }
            },
            {
                name: 'sbc',
                path: '/sbc/order',
                component: () => import('@/views/default/DefaultOrder'),
                meta: {
                    title: 'Sbc',
                    locale: 'da'
                }   
            },
            {
                name: 'sbccreateorder',
                path: '/sbc/createorder',
                component: () => import('@/views/default/CreateOrder'),
                meta: {
                    title: 'Sbc',
                    locale: 'da'
                }
            },
            {
                name: 'vestfor',
                path: '/vestfor/order',
                component: () => import('@/views/default/DefaultOrder'),
                meta: {
                    title: 'Vestfor',
                    locale: 'da'
                }
            },
            {
                name: 'vestforcreateorder',
                path: '/vestfor/createorder',
                component: () => import('@/views/default/CreateOrder'),
                meta: {
                    title: 'Vestfor',
                    locale: 'da'
                }
            },
            {
                name: 'optidata',
                path: '/optidata/order',
                component: () => import('@/views/default/DefaultOrder'),
                meta: {
                    title: 'Optidata',
                    locale: 'da'
                }
            },
            {
                name: 'optidatacreateorder',
                path: '/optidata/createorder',
                params: {locale: 'se'},
                component: () => import('@/views/default/CreateOrder'),
                meta: {
                    title: 'Optidata',
                    locale: 'da'
                }
            },
            {
                name: 'demo',
                path: '/demo/order',
                component: () => import('@/views/default/DefaultOrder'),
                meta: {
                    title: 'Demo',
                    locale: 'da'
                }
            },
            {
                name: 'democreateorder',
                path: '/demo/createorder',
                component: () => import('@/views/default/CreateOrder'),
                meta: {
                    title: 'Demo',
                    locale: 'da'
                }
            },
            {
                name: 'dev1',
                path: '/dev1/order',
                component: () => import('@/views/default/DefaultOrder'),
                meta: {
                    title: 'Dev1',
                    locale: 'da'
                }
            },
            {
                name: 'dev1createorder',
                path: '/dev1/createorder',
                component: () => import('@/views/default/CreateOrder'),
                meta: {
                    title: 'Dev1',
                    locale: 'da'
                }
            },
            {
                name: 'dev2',
                path: '/dev2/order',
                component: () => import('@/views/default/DefaultOrder'),
                meta: {
                    title: 'Dev2',
                    locale: 'da'
                }
            },
            {
                name: 'dev2createorder',
                path: '/dev2/createorder',
                component: () => import('@/views/default/CreateOrder'),
                meta: {
                    title: 'Dev2',
                    locale: 'da'
                }
            },
            {
                name: 'dev3',
                path: '/dev3/order',
                component: () => import('@/views/default/DefaultOrder'),
                meta: {
                    title: 'Dev3',
                    locale: 'da'
                }
            },
            {
                name: 'dev3createorder',
                locale: 'se',
                path: '/dev3/createorder',
                component: () => import('@/views/default/CreateOrder'),
                meta: {
                    title: 'Dev3',
                    locale: 'da'
                }
            },
            {
                name: 'seoptidata',
                path: '/seoptidata/order',
                component: () => import('@/views/default/DefaultOrder'),
                meta: {
                    title: 'SeOptidata',
                    locale: 'se'
                }
            },
            {
                name: 'seoptidatacreateorder',
                path: '/seoptidata/createorder',
                component: () => import('@/views/default/CreateOrder'),
                meta: {
                    title: 'SeOptidata',
                    locale: 'se'
                }
            },
            {
                name: 'sedemo',
                path: '/sedemo/order',
                component: () => import('@/views/default/DefaultOrder'),
                meta: {
                    title: 'SeDemo',
                    locale: 'se'
                }
            },
            {
                name: 'sedemocreateorder',
                path: '/sedemo/createorder',
                component: () => import('@/views/default/CreateOrder'),
                meta: {
                    title: 'SeDemo',
                    locale: 'se'
                }
            },

        ],
    },
    {
        name: 'errorOrderNotFound',
        path: '/ErrorOrderNotFound',
        component: () => import('@/views/pages/ErrorOrderNotFound')
    }

]

const router = createRouter({
    history: createWebHistory(),
    routes
})

// import { I18n  } from 'vue-i18n'

router.beforeEach(to => {
    document.title = to.meta.title || 'Optidata'


    // this.$i18n.locale = to.meta.locale
    // this.$i18n.setLocaleMessage(require(`@/locales/${to.meta.locale}.json`))

    // console.log($i18n)
})

export default router